<template>
  <v-card>
    <v-card-title class="mb-3">
      <span class="text-h6">{{ $t("labels.customer") }}</span>
      <v-spacer></v-spacer>

      <v-btn
        small
        color="warning"
        outlined
        class="mr-1"
        @click="showDialogChangePassword"
      >
        {{ $t("labels.change_password") }}
      </v-btn>
      <v-btn small color="primary" outlined class="mr-1" @click="submitDialog">
        {{ $t("labels.save") }}
      </v-btn>
      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            :label="$t('labels.company_name')"
            dense
            outlined
            hide-details
            class="c-input-small"
            v-model="detail.company_name"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="detail.id_country"
            :items="countries"
            :label="$t('labels.country')"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="detail.id_warehouses"
            :items="warehouses"
            :label="$t('labels.warehouse')"
            dense
            outlined
            hide-details
            class="c-input-small"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="detail.id_material_warehouses"
            :items="material_warehouses"
            :label="$t('labels.material_warehouse')"
            dense
            outlined
            hide-details
            class="c-input-small"
            multiple
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('labels.email_report')"
            placeholder="email1@gmail.com;email2@gmail.com;email3@gmail.com"
            dense
            outlined
            hide-details
            class="c-input-small"
            v-model="detail.email_cc"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('labels.email_goods_receipt')"
            placeholder="email1@gmail.com;email2@gmail.com;email3@gmail.com"
            dense
            outlined
            hide-details
            class="c-input-small"
            v-model="detail.email_goods_receipt"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('labels.email_goods_return')"
            placeholder="email1@gmail.com;email2@gmail.com;email3@gmail.com"
            dense
            outlined
            hide-details
            class="c-input-small"
            v-model="detail.email_goods_return"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            :label="$t('labels.email_goods_quantity')"
            placeholder="email1@gmail.com;email2@gmail.com;email3@gmail.com"
            dense
            outlined
            hide-details
            class="c-input-small"
            v-model="detail.email_goods_quantity"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="detail.order_webhook_url"
            :label="$t('labels.order_webhook')"
            placeholder="https://domain.com/webhook-name"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="detail.special_barcode_nhap_kho_webhook_url"
            :label="$t('labels.receipt_webhook')"
            placeholder="https://domain.com/webhook-name"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="detail.special_barcode_dong_goi_webhook_url"
            :label="$t('labels.packing_webhook')"
            placeholder="https://domain.com/webhook-name"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="detail.special_barcode_nhap_hoan_webhook_url"
            :label="$t('labels.return_webhook')"
            placeholder="https://domain.com/webhook-name"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="detail.goods_quantity_webhook_url"
            :label="$t('labels.quantity_webhook')"
            placeholder="https://domain.com/webhook-name"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-textarea
            v-model="detail.custom_headers"
            :label="$t('labels.header_webhook')"
            placeholder="{Token: 'TokenString'}"
            dense
            outlined
            hide-details
            class="c-input-small"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>
      <div>
        <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
      </div>
    </v-card-text>

    <!-- <template v-if="checkPermission(['supper_admin'])">
      <v-card-text class="mt-5">
        <div class="text-h6">{{ $t("labels.warehouse") }}</div>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in warehouseModules"
            :key="`w_${item.value}`"
          >
            <v-checkbox
              dense
              hide-details
              class="c-input-small"
              v-model="detail.id_modules"
              :value="item.value"
              :label="item.text"
              multiple
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="detail.is_active_pos">
        <div class="text-h6">{{ $t("labels.pos") }}</div>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in posModules"
            :key="`p_${item.value}`"
          >
            <v-checkbox
              dense
              hide-details
              class="c-input-small"
              v-model="detail.id_modules"
              :value="item.value"
              :label="item.text"
              multiple
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <div class="text-h6">{{ $t("labels.finance") }}</div>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in financeModules"
            :key="`p_${item.value}`"
          >
            <v-checkbox
              dense
              hide-details
              class="c-input-small"
              v-model="detail.id_modules"
              :value="item.value"
              :label="item.text"
              multiple
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <div class="text-h6">{{ $t("labels.garment_production") }}</div>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            v-for="item in garmentProductionModules"
            :key="`p_${item.value}`"
          >
            <v-checkbox
              dense
              hide-details
              class="c-input-small"
              v-model="detail.id_modules"
              :value="item.value"
              :label="item.text"
              multiple
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </template> -->

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h6 mb-3">
          {{ $t("labels.change_password") }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="password"
            class="c-input-xs mb-3"
            :label="$t('labels.password')"
            autocomplete="off"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="resetPassword"
            :disabled="!password || password.length < 6"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "DialogDetail",
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detail: {},
    errMsg: null,
    countries: [],
    warehouses: [],
    material_warehouses: [],
    devices: [],
    password: null,
    passwordShow: false,
    dialogChangePassword: false,
    permissions: [],
    modules: [],
  }),
  computed: {
    warehouseModules() {
      return [...this.modules]
        .filter((d) => d.type === 1)
        .map((d) => ({
          text: d.description,
          value: d.id,
        }));
    },
    posModules() {
      return [...this.modules]
        .filter((d) => d.type === 2)
        .map((d) => ({
          text: d.description,
          value: d.id,
        }));
    },
    financeModules() {
      return [...this.modules]
        .filter((d) => d.type === 3)
        .map((d) => ({
          text: d.description,
          value: d.id,
        }));
    },
    nscaModules() {
      return [...this.modules]
        .filter((d) => d.type === 4)
        .map((d) => ({
          text: d.description,
          value: d.id,
        }));
    },
    garmentProductionModules() {
      return [...this.modules]
        .filter((d) => d.type === 5)
        .map((d) => ({
          text: d.description,
          value: d.id,
        }));
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    async submitDialog() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/customer/v1/update-info", this.detail);
        this.closeDialog();
        this.refreshData();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    getDetailCustomer() {
      httpClient
        .post("/customer/v1/detail", {
          id: this.customer.id,
        })
        .then(({ data }) => {
          const customer = { ...data.customer };
          customer.id_warehouses = (data.warehouses || []).map((i) => i.id);
          customer.id_material_warehouses = (
            data.material_warehouses || []
          ).map((i) => i.id);
          customer.id_modules = (data.modules || []).map((i) => i.id_module);
          this.detail = { ...customer };
        });
    },
    async getCountries() {
      const { data } = await httpClient.post("/common/v1/get-country");
      this.countries = data.map((e) => ({
        value: e.id,
        text: e.name,
      }));
    },
    async getWarehouses() {
      const { data } = await httpClient.post("/warehouse/v1/get-all");
      this.warehouses = data.map((e) => ({
        value: e.id,
        text: `${e.code_name || ""} ${!e.active ? "(inactive)" : ""}`,
      }));
    },
    async getMaterialWarehouses() {
      const { data } = await httpClient.post("/material-warehouse/v1/get-all");
      this.material_warehouses = data.map((e) => ({
        value: e.id,
        text: `${e.code || ""} ${!e.status ? "(inactive)" : ""}`,
      }));
    },
    showDialogChangePassword() {
      this.dialogChangePassword = true;
    },
    hideDialogChangePassword() {
      this.dialogChangePassword = false;
    },
    async resetPassword() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        await httpClient.post("/customer/v1/update-password", {
          password: this.password,
          identity_id: this.detail.id_identity,
        });
        this.hideDialogChangePassword();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getModules() {
      const { data } = await httpClient.post("/customer/v1/list-module");
      this.modules = [...data];
    },
  },
  mounted() {
    this.getDetailCustomer();
    this.getWarehouses();
    this.getMaterialWarehouses();
    this.getModules();
    this.getCountries();
  },
};
</script>

<style scoped lang="scss">
.permission-col {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  position: relative;
}
</style>
